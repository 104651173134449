.toolbar[data-v-36f24568] {
  padding: 8px 0;
}
.toolbar .calendar[data-v-36f24568] {
  margin-left: 10px;
}
.toolbar[data-v-36f24568] .el-select-tags-wrapper {
  display: flex !important;
}
@media (max-width: 640px) {
.toolbar[data-v-36f24568] {
    display: flex;
    flex-direction: column;
}
.calendarButton[data-v-36f24568] {
    display: none !important;
}
.calendarButtonPhone[data-v-36f24568] {
    margin-right: 8px !important;
}
.calendarButton[data-v-36f24568] {
    display: block;
    border-radius: unset !important;
    margin-bottom: 8px !important;
}
[data-v-36f24568] .el-date-editor.el-input {
    width: 100% !important;
    margin-bottom: 8px !important;
}
[data-v-36f24568] .ml-4 {
    margin-left: 0px !important;
}
.dataSelect[data-v-36f24568],
  .dataSelectMultiple[data-v-36f24568],
  .dataSelectStr[data-v-36f24568] {
    margin-bottom: 8px !important;
}
}
@media (min-width: 640px) {
.refresh[data-v-36f24568] {
    float: right;
    margin-top: 8px !important;
}
.dataSelect[data-v-36f24568] .el-input__inner {
    height: 22px !important;
}
.company-select[data-v-36f24568] {
    width: 300px;
}
.calendarButtonPhone[data-v-36f24568] {
    display: none !important;
}
.dataSelectStr[data-v-36f24568] {
    margin-right: 8px;
    margin-top: 8px;
    display: inline-block;
    vertical-align: middle;
}
[data-v-36f24568] .el-date-editor.el-input {
    margin-right: 8px !important;
    margin-top: 8px !important;
}
.dataSelect[data-v-36f24568],
  .dataSelectMultiple[data-v-36f24568] {
    margin-right: 8px !important;
    margin-top: 8px !important;
}
.calendarButton[data-v-36f24568] {
    margin-top: 8px !important;
}
}
