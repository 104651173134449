.container[data-v-edcd8373] {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
[data-v-edcd8373] .toggle-btn {
  display: none;
}
[data-v-edcd8373] .tools {
  padding-top: 0 !important;
}
.title[data-v-edcd8373] {
  height: 75px;
  line-height: 75px;
  margin-bottom: 0;
}
